import React from 'react';
import './style.css';

const LeftContent = () => {
  return (
    <div
      className="header__left-content flex text-center"
    >
      <img
        className=""
        alt="easytopay header"
        src="/images/easytopay_header_logo.png"
      />
    </div>
  );
};

const RightContent = () => {
  return (
    <div
      className="header__right-content flex text-center" style={{alignItems: 'flex-end'}}
    >
      <img
        className=""
        alt="easytopay header"
        style={{height: '50%'}}
        src="/images/easytopay_header_text.png"
      />
    </div>
  );
};

const Header = () => {
  return (
    <div className="header-wrapper" style={{justifyContent: 'center', gap: '20%'}}>
        <LeftContent />
        <RightContent />
    </div>
  );
};

export default Header;
