import React from 'react';
import Header from './header.js';
import Contact from './contact.js';
import PriceBox from './pricebox.js';
import {Payment3BBanner ,PaymentBanner} from './bar.js';

import './style.css';

const EasyToPay = ({ addStyle }) => {

  return (
    <div className="s1">
      <Header/>
      <br/>
      <Contact/>
      <br/>
      <div className='easytopay-title-line estpay-relative'>
        <p className='easytopay-title'>Window World Payment Options Worksheet</p>
        <img
            src="/images/darkblue.png"
            alt="red"
            width="100%"
            height="46.8px"
            className="bg-img-print"
          />
      </div>
      <br/>
      <PriceBox/>
      <br/>
      <PaymentBanner
        number="1"
        duration="18 Months No Interest"
        description="Financed amount no interest with minimum monthly payments."
        amount="344.80"
        mostpopular={false}
        extra={[]}
      />
      <PaymentBanner
        number="2"
        duration="48 Months at 8.99%"
        description=" "
        amount="155.72"
        mostpopular={false}
        extra={[]}
      />
      <PaymentBanner
        number="3"
        duration="120 Months at 9.99%"
        description="Minimum finance amount $7,500"
        amount="82.25"
        mostpopular={true}
        extra={[]}
      />
      <Payment3BBanner
        number="3b"
        duration=""
        description=""
        amount=""
        mostpopular={false}
      />
      <br/>
      <br/>
      <PaymentBanner
        number="4"
        duration="Credit or Debit Card"
        description=""
        amount=""
        mostpopular={false}
        extra={[6896, 3448, 3448]}
      />
      <br/>
      <br/>
      <PaymentBanner
        number="5"
        duration="Cash or Check"
        description=""
        amount=""
        mostpopular={false}
        extra={[6896, 3448, 3448]}
      />
      <br/>
      <br/>
      <div className='etp-footer'>
      <p className='etp-footer-text'>Credit or Debit Card, Cash or Check Savings are calculated upon payment method.</p>
      <p className='etp-footer-text'>Donations to St Judes cannot be financed.</p>
      <p className='etp-footer-text'>This document is intended to display estimated payment options only. Payment options valid for 30 days from date 
provided. All financing requires Credit Approval.</p>
        </div>
        
      <br/>
      <br/>

      <div className='etp-footer-banner estpay-relative'>
        <p className='etp-footer-banner-1'>NO PREPAYMENT PENALTIES, PAY OFF AT ANY TIME</p>
        <p className='etp-footer-banner-2'>windowworld.com/financing</p>
        <img
            src="/images/red.png"
            alt="red"
            width="100%"
            height="66.5px"
            className="bg-img-print"
            style={{zIndex: 0}}
          />
      </div>
    </div>
  );
};

export default EasyToPay;
