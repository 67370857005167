import React from 'react';
import { numberWithCommas, dollarNumberWithCommas } from '../../utils/globals';
import './style.css';

const PaymentBanner = ({ number, duration, description, amount, mostpopular, extra }) => {
  return (
    <div className="container">
      {/* Left circle with the number */}
      <div className="leftCircle">
        <span>{number}</span>
      </div>

      {/* Middle section with offer details */}
      <div className="details estpay-relative" style={extra.length > 0 ? {width : '25%'} : {}}>
        <h2 className="details-title">{duration}</h2>
        <p className="details-description">{description}</p>
        <img
            src="/images/darkblue.png"
            alt="darkblue"
            width="100%"
            height="68px"
            className="bg-img-print"
          />
      </div>

      {/* Right section with payment details */}
      <div className="payment">
        {
            extra.length ? <div className="extras-container">
                {
                    extra.map( (e, i) => <div className="e-arrow">
                        <span className="e-arrow-text">
                            <span>$</span>
                            <span>{numberWithCommas(Number(e))}</span>
                        </span>
                        {i != extra.length - 1 && <img src="/images/arrow.svg" className="e-arrow-img"/>}
                    </div>)
                }
            </div> : <>
                <span className="currency">$</span>
                <span className="amount">{numberWithCommas(Number(amount))}</span>
            </>
        }
      </div>
      
      {extra.length ? <div className='extras'>
        <p className='extra period'>New Project Total</p>
        <p className='extra period'>50% Custom Order Deposit</p>
        <p className='extra period'>Card Balance Due Upon Completion</p>
      </div>: <p className='period'>Monthly Payment</p>}
      {mostpopular && <div className='flex hexagon-star-container'>
        <img src="/images/popular.svg" className="hexagon-star"/>
        <span className="hexagon-star-text">Most Popular</span>
        </div>}
    </div>
  );
};

const Payment3BBanner = ({ number, duration, description, amount, mostpopular }) => {
    return (
      <div className="b3-banner-container">
        {/* Left circle with the number */}
        <div className="b3-banner-leftCircle">
          <span>{number}</span>
        </div>
  
        {/* Middle section with offer details */}
        <div className="b3-banner-details-1">
          <h2 className="b3-banner-details-title">{duration}</h2>
          <p className="b3-banner-details-description">{description}</p>
        </div>
        <div className="b3-banner-details-2">
          <h2 className="b3-banner-details-title">{duration}</h2>
          <p className="b3-banner-details-description">{description}</p>
        </div>
        <div className="b3-banner-details estpay-relative">
          <h2 className="b3-banner-details-title">{duration}</h2>
          <p className="b3-banner-details-description">{description}</p>
          <img
            src="/images/darkblue.png"
            alt="darkblue"
            width="100%"
            height="68px"
            className="bg-img-print"
          />
        </div>
  
        {/* Right section with payment details */}
        <div className="b3-banner-payment">
            <span className="b3-banner-currency">$</span>
            <span className="b3-banner-amount">{numberWithCommas(Number(amount))}</span>
        </div>
        
        <div className='b3-banner-period-group'>
            <p className='b3-banner-period'>Months</p>
            <p className='b3-banner-period'>Rate</p>
            <p className='b3-banner-period'>Monthly Payment</p>
        </div>
        
        {mostpopular && <div className='flex b3-banner-hexagon-star-container'>
          <img src="/images/popular.svg" className="b3-banner-hexagon-star"/>
          <span className="b3-banner-hexagon-star-text">Most Popular</span>
          </div>}
      </div>
    );
  };

export {PaymentBanner, Payment3BBanner};