import React, { useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { numberWithCommas, dollarNumberWithCommas } from '../../utils/globals';
import {
  updateDataEasyToPay,
  updateValue,
} from '../../store/slices/easytopaySlice';
import './style.css';

const Box = ({title, amount, editable}) => {
  const dispatch = useDispatch();
  const easytopay = useSelector((state) => state.easytopay.data);
  const downpaymentRef = useRef();
  let flag = useRef(false);

  useEffect(() => {
    if(editable) {
      downpaymentRef.current.value = numberWithCommas(
        easytopay?.easytopay_down || ''
      );
    }
    
  }, [easytopay, editable]);

  const handleFocus = (e, id) => {
    e.target.type = 'number';
    e.target.value = easytopay[id];
    flag.current = true;
  };

  const handleBlur = (e, id) => {
    e.target.type = 'text';
    e.target.value = numberWithCommas(easytopay[id] || editable.initValue);
    flag.current = false;
  };

  return (
    <div
      className="flex easytopay-box-container"
    >
       <div className="easytopay-box-header estpay-relative">
        {title}
        <img
            src="/images/lightblue.png"
            alt="lightblue"
            width="100%"
            height="26.5px"
            className="bg-img-print"
        />
       </div>
       <div className="easytopay-box-content">
        <span>$</span>
        {editable ? 
          <input id="" ref={downpaymentRef} class="bottom-outline medium-input" type="text"
            onChange={(e) => {
              dispatch(updateValue({id: editable?.id || 'easytopay_down', value: e.target.value}));
            }}
            onFocus={(e) => handleFocus(e, 'easytopay_down')}
            onBlur={(e) => handleBlur(e, 'easytopay_down')}
            style={{width: '35%', textAlign: 'left'}}/> : 
          <span>{amount}</span>}
       </div>
    </div>
  );
};

const PriceBox = () => {
  const storeData = useSelector((state) => state.calculate.data);
  const easytopay = useSelector((state) => state.easytopay.data);
  const dispatch = useDispatch();
  
  useEffect(() => {
    if(!easytopay?.easytopay_down) {
      dispatch(updateValue({id: 'easytopay_down', value: (Number(storeData['contractsubtotal']) +
      Number(storeData['calculateInput1']) +
      Number(storeData['calculateInput2'])) / 10}));
    }
  }, [storeData]);

  return (
    <div className="header-wrapper" style={{justifyContent: 'center', gap: '5%', display: 'flex', alignItems:'center', padding: '0 5%'}}>
        <Box title="Project Total" amount={numberWithCommas(
          Number(storeData['contractsubtotal']) +
          Number(storeData['calculateInput1']) +
          Number(storeData['calculateInput2'])
        )}/>
        <Box title="Down Payment" amount="690" editable={{
          initValue: (
            (Number(storeData['contractsubtotal']) +
            Number(storeData['calculateInput1']) +
            Number(storeData['calculateInput2'])) / 10
          ),
          id: 'easytopay_down'
        }}/>
        <Box title="Financed Amount" amount={numberWithCommas(
          ((Number(storeData['contractsubtotal']) +
          Number(storeData['calculateInput1']) +
          Number(storeData['calculateInput2'])) - 
          (Number(easytopay?.easytopay_down || (Number(storeData['contractsubtotal']) +
          Number(storeData['calculateInput1']) +
          Number(storeData['calculateInput2'])) / 10))).toFixed(2)
        )}/>
    </div>
  );
};

export default PriceBox;
