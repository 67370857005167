import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {
    easytopay_down: ''
  },
};

function padTo2Digits(num) {
  return num.toString().padStart(2, '0');
}

function formatDate(date) {
  return [
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
    date.getFullYear(),
  ].join('/');
}

export const easyTopPaySlice = createSlice({
  name: 'easytopay',
  initialState,
  reducers: {
    updateValue: (state, action) => {
      state.data[action.payload.id] = action.payload.value || '';
    },
    updateDataEasyToPay: (state, action) => {
      state.data = action.payload;
    },
    init: (state, action) => {
      state.data = initialState.data;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateValue, updateDataEasyToPay, init } = easyTopPaySlice.actions;

export default easyTopPaySlice.reducer;
