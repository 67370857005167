import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import TaskIcon from '@mui/icons-material/Task';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAllContracts, getAllContractById } from '../../utils/authApi';
import Stack from '@mui/material/Stack';

import { store } from '../../store/store';
import { useSelector, useDispatch } from 'react-redux';
import { API_URL } from '../../constants';
import axios from 'axios';
import { updateDataContact } from '../../store/slices/contactSlice';
import { updateDataBottompage } from '../../store/slices/bottompage3Slice';
import { updateDataCalculate } from '../../store/slices/calculateSlice';
import { updateDataCredit } from '../../store/slices/creditSlice';
import { updateDataGlassoption } from '../../store/slices/glassoptionSlice';
import { updateDataJobinfo } from '../../store/slices/jobinfoSlice';
import { updateDataMiscellenous } from '../../store/slices/miscellenousSlice';
import { updateDataPrebuilt } from '../../store/slices/prebuiltSlice';
import { updateDataRoundup } from '../../store/slices/roundupSlice';
import { updateDataSalesman } from '../../store/slices/salesmanSlice';
import { updateDataSalesperson } from '../../store/slices/salespersonSlice';
import { updateDataTable23 } from '../../store/slices/table23Slice';
import { updateDataTable33 } from '../../store/slices/table33Slice';
import { updateDataTable43 } from '../../store/slices/table43Slice';
import { updateDataVinylsliding } from '../../store/slices/vinylslidingSlice';
import { updateDataWindowoption } from '../../store/slices/windowoptionSlice';
import { updateDataWindowtable } from '../../store/slices/windowtableSlice';
import { updateDataWindowworld } from '../../store/slices/windowworldSlice';
import { updateDataMeasureSheet } from '../../store/slices/measuresheetSlice';
import { updateDataPatioDoor } from '../../store/slices/patioDoorOrderSlice';
import { updateDataWindowOrder } from '../../store/slices/windoworderSlice';
import { updateDataSalesInfo } from '../../store/slices/salesInfoSlice';
import { updateDataEasyToPay } from '../../store/slices/easytopaySlice';
import { useAuthDataContext } from '../../components/AuthProvider';

const GmailTreeView = () => {
  const navigate = useNavigate();
  const { authData, isdemo } = useAuthDataContext();
  useEffect(() => {
    if (isdemo || !authData.email) {
      navigate('/');
    }
  }, [authData, isdemo]);

  const [contracts, setContracts] = useState([]);
  const { pathname } = useLocation();
  const [selectedNum, setSelectedNum] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    getAllContracts({
      date: pathname.split('/')[pathname.split('/').length - 1],
      creator: authData.email,
    })
      .then(({ data }) => {
        setContracts(data);
      })
      .catch((err) => {});
  }, [pathname, authData]);
  const generateLabel = (name, date) =>
    `${name} ${new Date(date).getMonth() + 1}-${new Date(date).getDate()}-${
      new Date(date).getFullYear() - 2000
    } ${new Date(date).getHours()}:${new Date(date).getMinutes()}`;
  const generateInfo = (date) =>
    `${new Date(date).getHours()}:${new Date(date).getMinutes() + 1}`;
  const postDataAsync = async (data) => {
    const response = await axios.post(
      `${API_URL}/contract/duplicate`,
      data.data
    );
    return response;
  };
  const updateStore = (data) => {
    store.dispatch(updateDataContact(data.contact.data));
    store.dispatch(updateDataBottompage(data.bottompage3.data));
    store.dispatch(updateDataCalculate(data.calculate.data));
    store.dispatch(updateDataCredit(data.credit.data));
    store.dispatch(updateDataGlassoption(data.glassoption.data));
    store.dispatch(updateDataPrebuilt(data.prebuilt.data));
    store.dispatch(updateDataJobinfo(data.jobinfo.data));
    store.dispatch(updateDataMiscellenous(data.miscellenous.data));
    store.dispatch(updateDataRoundup(data.roundup.data));
    store.dispatch(updateDataSalesman(data.salesman.data));
    store.dispatch(updateDataSalesperson(data.salesperson.data));
    store.dispatch(updateDataTable23(data.table23.data));
    store.dispatch(updateDataTable33(data.table33.data));
    store.dispatch(updateDataTable43(data.table43.data));
    store.dispatch(updateDataVinylsliding(data.vinylsliding.data));
    store.dispatch(updateDataWindowoption(data.windowoption.data));
    store.dispatch(updateDataWindowtable(data.windowtable.data));
    store.dispatch(updateDataWindowworld(data.windowworld.data));
    store.dispatch(updateDataMeasureSheet(data.measuresheet.data));
    store.dispatch(updateDataPatioDoor(data.patiodoororder.data));
    store.dispatch(updateDataWindowOrder(data.windoworder.data));
    store.dispatch(updateDataSalesInfo(data.salesInfo.data));
    store.dispatch(updateDataEasyToPay(data.easytopay?.data || {}));
  };

  const onOpen = async ({ id, contract_info }) => {
    const { data } = await getAllContractById({ id: id });
    updateStore(JSON.parse(data.contract_info));
    navigate('/documentation');
  };
  const onDuplicate = async (contract) => {
    const res = await postDataAsync({
      data: {
        customer_name: `#Dup# - ${contract.customer_name}`,
        customer_email: contract.customer_email,
        contract_info: JSON.parse(contract.contract_info),
      },
    });
    setSelectedNum(0);
    setContracts([
      ...contracts,
      {
        ...contract,
        id: res.data[0],
        customer_name: `#Dup# - ${contract.customer_name}`,
        created_at: new Date(),
      },
    ]);
  };
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  return (
    <>
      <List component="nav" aria-label="main mailbox folders">
        <ListItem sx={{ pl: 3, pt: 1, pb: 0 }}>
          <ListItemAvatar>
            <Avatar>
              <FolderIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              months[
                pathname
                  .split('/')
                  [pathname.split('/').length - 1].split('-')[1] - 1
              ] +
              ' ' +
              pathname.split('/')[pathname.split('/').length - 1].split('-')[0]
            }
            secondary={`${contracts.length} contracts`}
          />
        </ListItem>
      </List>
      <List
        component="nav"
        aria-label="main mailbox folders"
        sx={{ pt: 0, marginBottom: '100px' }}
      >
        {contracts.map((contract) => (
          <ListItem
            onClick={(event) => {
              setSelectedNum(contract.id);
            }}
            sx={{ pl: 6, pt: 0, pb: 0 }}
            key={contract.id}
            secondaryAction={
              selectedNum === contract.id ? (
                <Stack direction="row" spacing={1}>
                  <button onClick={() => onOpen(contract)}>Open</button>
                  <button onClick={() => onDuplicate(contract)}>
                    Duplicate
                  </button>
                </Stack>
              ) : null
            }
          >
            <ListItemAvatar>
              <Avatar>
                <TaskIcon />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={generateLabel(
                contract.customer_name,
                contract.created_at
              )}
              secondary={contract.customer_email}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};

export default GmailTreeView;
